body {
    font-family:
        Roboto,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Helvetica Neue,
        Arial,
        sans-serif;
}

.cover-full-height {
    min-height: 100vh;
}

small.validation-error {
    font-size: 12px;
}

p.validation-error {
    font-size: 14px;
}

.validation-error {
    display: inline-block;
    width: 100%;
    color: $crimson;
}

.pointer {
    cursor: pointer;
}

.staffnow-header {
    .staffnow-logo-container {
        width: 100px;
        #logo {
            fill: $lightGrey !important;
        }
    }
}

.splash-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $patina;
    font-size: 22px;
}

staff-loader {
    .splash-screen-loader {
        .dot {
            color: $patina !important;
            width: 20px !important;
            height: 20px !important;
        }
    }
    .btn-loader {
        .dot {
            color: $white !important;
            width: 10px !important;
            height: 10px !important;
        }
    }
    .disabled-btn-loader {
        .dot {
            color: $eden !important;
            width: 10px !important;
            height: 10px !important;
        }
    }
}

$t: 1.5s;
.staff-loader {
    text-align: center;
    margin: auto;
    .dot {
        color: $white;
        display: block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        &:after {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: animated $t calc(((var(--i) + var(--o, 0)) / var(--n) - 1) * #{$t}) infinite;
            --o: 1;
            background: currentcolor;
            content: '';
        }
    }

    .btn-loader {
        .dot {
            color: $white !important;
            width: 10px !important;
            height: 10px !important;
        }
    }

    .disabled-btn-loader {
        .dot {
            color: $eden !important;
            width: 10px !important;
            height: 10px !important;
        }
    }
}

button staff-loader .dot:first-child,
button staff-loader .dot:last-child {
    display: none;
}

.animated {
    display: flex;
    justify-content: center;
    align-items: center;
}

.purple-loader {
    .dot {
        color: $patina !important;
        width: 20px !important;
        height: 20px !important;
    }
}

@keyframes animated {
    0%,
    50% {
        transform: scale(0);
    }
}

//Default styles for a loading button
.loading-btn {
    &.loading {
        pointer-events: none;
        staff-loader {
            display: block !important;
        }
        .btn-text {
            display: none;
        }
    }
    staff-loader {
        display: none;
    }
}

.default-avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-size: contain;
    color: white;
    background-color: $patina;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .material-icons {
        font-size: 29px;
    }
}

.notifications-badge {
    position: relative;
}

.ACTIVE {
    color: $green;
}

.PENDING {
    color: $orange;
}

.IN_REVIEW {
    color: $orange;
}

.INCOMPLETE {
    color: $crimson;
}

.COMPLETE,
.VERIFIED {
    color: green;
}

.DEACTIVATED,
.DELETED {
    color: $crimson;
}

@include media-breakpoint-down(xs) {
    .notifications-opened {
        display: none;
    }
}

.no-cover {
    display: grid;
    align-items: center;
    height: 230px;
    background: $eden;
    user-select: none;
    justify-content: center;

    .material-icons {
        color: $white;
        font-size: 80px;
    }
}

.facility-logo-placeholder {
    text-align: center;
    display: grid;
    padding: 5px;
    width: 100px;
    height: 100px;
    background-color: $gray-dark;
    color: $patina;
    font-weight: bold;
    .logo-text {
        line-height: 21px;
        font-size: 21px;
    }
    .company-text {
        color: $white;
        line-height: 11px;
        font-size: 11px;
    }
}

.facility-profile-modal {
    width: unset;
    .modal-content {
        padding: 0;
    }
}

.date-picker {
    padding-right: 30px !important;
    background-image: url('../assets/images/calendar-icon.svg');
    background-repeat: no-repeat;
    background-position: 96%;
    cursor: pointer;

    &.disabled {
        color: $gray-dark;
        background-color: $lightGrey;
        opacity: 1;
    }
}

.modal-center {
    top: 50%;
    transform: translateY(-50%) !important;
}

.modal-center-adjusted {
    transform: translateY(3%) !important;
}

.modal-center-horizontal {
    margin: auto;
}

.modal-big {
    max-width: 1000px;
}

.modal-range-picker .modal-content {
    height: 110% !important;
}

.cards-list-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 296px);
    grid-gap: 30px;
    width: 100%;
    height: 100%;
}

.modal-center-extra-padding {
    @extend .modal-center;
    .modal-content {
        padding: 2rem !important;
    }
}

.ng-select.warning-form-control-availability .ng-select-container {
    padding-left: 26px !important;
}

.ng-select.is-invalid .ng-select-container,
.custom-select.is-invalid .ng-select-container,
.material-form-control.is-invalid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff505a' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff505a' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    --mdc-outlined-text-field-outline-color: red;
    --mdc-outlined-text-field-focus-outline-color: red;
    --mdc-outlined-text-field-hover-outline-color: red;
}

.ng-select.is-invalid .ng-select-container,
.custom-select.is-invalid .ng-select-container {
    border-color: $signalRed !important;
}

.ng-select.is-valid .ng-select-container,
.custom-controls-stacked.is-valid .ng-select-container,
.material-form-control.is-valid {
    padding-bottom: 0;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2305a318' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    --mdc-outlined-text-field-outline-color: green;
    --mdc-outlined-text-field-focus-outline-color: green;
    --mdc-outlined-text-field-hover-outline-color: green;
}

.ng-select.is-valid .ng-select-container,
.custom-controls-stacked.is-valid .ng-select-container {
    border-color: $laurel;
}

.ng-select.missing-data-warning .ng-select-container {
    padding-left: 25px;
}

.invalid-form-error {
    text-align: center;
}

label.required::after {
    content: ' *';
    color: $red;
}

.material-form-control {
    display: flex;
    width: 100%;
    font-size: $font-size-base !important;
    line-height: $input-btn-line-height;
    color: $input-color !important;
    background-color: $input-bg;
    background-image: none;
    background-clip: padding-box;
    align-items: center;
    margin: 0;
    padding: 0;
    overflow: hidden;

    .mat-input-element::placeholder {
        color: $tundora !important;
    }
}

mat-form-field.disabled {
    background-color: $lightGrey;
}
